<script setup>
import { useRouter } from 'nuxt/app';
import { computed } from 'vue';
import { useAuthenticationStore } from '~/stores/account/authentication.store';
import helper from '~/resources/js/libraries/helper';

const authStore = useAuthenticationStore();
const router = useRouter();
const localePath = useLocalePath()
const { $toast } = useNuxtApp()

function handleOTPInput(event) {
    const input = event.target;
    const value = input.value;
    if (value.length > 1) {
        input.value = value.slice(-1);
    }
    if (value.length === 1) {
        const nextInput = input.nextElementSibling;
        if (nextInput) {
            nextInput.focus();
        } else {
            verify(); 
        }
    }
    if (event.key === 'Backspace' || value.length === 0) {
        const previousInput = input.previousElementSibling;
        if (previousInput) {
            previousInput.focus();
        }
    }
    if (value.length > 0 && value.length <= 1) {
        input.value = value;
    } else {
        input.value = '';
    }
}

const inputs = ref({
    input1: null,
    input2: null,
    input3: null,
    input4: null,

});

const email = computed(() => helper.localStorage.getData('vartur-email'))

const verify = async () =>  {
    let code = '';
    for (let key in inputs.value) {
        code += inputs.value[key].toString();
    }
    const form = {email: email.value, activation_code: code}
    await authStore.verifyAccount(form)
        .then((res) => {
            router.push({ path: localePath({ name: 'account.login' }) });
        })
        .catch((err) => {
            const defaultMessage = 'Something Went Wrong Please Try Again'
            $toast.addToast({ title: err.activation_code[0] || defaultMessage, color: "red", position: 'top-10' })  
        })
};
</script>


<template>
    <header class="sticky top-0 z-10">
        <div class="px-4 h-20 bg-indigo-900 flex items-center justify-between text-white relative">
            <NuxtLink :to="localePath({ name: 'account.register' })"> <i class="fa-solid fa-chevron-left fa-xl"></i> </NuxtLink>
            <div class="flex flex-col justify-center items-center absolute left-1/2 -translate-x-1/2 w-full">
                <h1 class="text-center text-white text-2xl font-bold block">{{ $t('client.verify_account') }}</h1>
            </div>
        </div>
    </header>
    <main class="mb-32 px-4">
        <div class=" my-3 pb-6">
            <div class="h-24 w-24 block relative bg-gray-300 rounded-full mx-auto text-center">
                <i
                    class="fa-solid fa-envelope fa-2xl text-white absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"></i>
            </div>
            <div class="mx-auto text-center mt-3 block">
                <h3 class="text-2xl mb-2 font-medium leading-normal">{{ $t('client.verify_account') }}</h3>
                <p class="text-gray-600">
                    {{ $t('client.we_have_sent_a_code_to_your_email_address') }}
                    <br>  
                    <span class="text-indigo-900 font-medium">
                        {{ email }}
                    </span>
                </p>
                </div> 
                <div class="mt-3 flex items-center gap-3 justify-center">
                    <input 
                        @input="handleOTPInput"
                        v-model="inputs.input1"
                        type="number" 
                        placeholder=""
                        class="block ring-1 text-center w-16 h-16 ring-gray-300 ring-inset shadow-sm text-gray-900 text-xl p-3 rounded-md"
                    />
                    <input 
                        @input="handleOTPInput"
                        v-model="inputs.input2"
                        type="number" 
                        placeholder=""
                        class="block ring-1 text-center w-16 h-16 ring-gray-300 ring-inset shadow-sm text-gray-900 text-xl p-3 rounded-md"
                    />
                    <input 
                        @input="handleOTPInput"
                        v-model="inputs.input3"
                        type="number" 
                        placeholder=""
                        class="block ring-1 text-center w-16 h-16 ring-gray-300 ring-inset shadow-sm text-gray-900 text-xl p-3 rounded-md"
                    />
                    <input
                        @input="handleOTPInput"
                        v-model="inputs.input4"
                        type="number" 
                        placeholder=""
                        class="block ring-1 text-center w-16 h-16 ring-gray-300 ring-inset shadow-sm text-gray-900 text-xl p-3 rounded-md"
                    />
                </div>
            </div>
        <div class="flex items-center gap-3">
            <a 
                @click="verify"
                class="bg-indigo-900 text-white rounded-lg p-3 capitalize  w-full text-center"
            >
                {{ $t('client.confirm') }}
            </a>
        </div>
    </main>
</template>